import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/about-us",
    name: "About Us",
    component: () => import("../views/AboutUsView.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () => import("../views/ServicesView.vue"),
  },
  {
    path: "/how-works",
    name: "How works",
    component: () => import("../views/HowWorksView.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/references",
    name: "References",
    component: () => import("../views/ReferencesView.vue"),
  },
  {
    path: "/GDPR",
    name: "GDPR",
    component: () => import("../views/GDPRView.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/As/LoginView.vue"),
  },
  {
    path: "/web-system",
    name: "WebSystem",
    component: () => import("../views/As/WebSystem.vue"),
  },
  {
    path: "/page",
    name: "EditPage",
    component: () => import("../views/As/EditOfferView.vue"),
  },
  {
    path: "/cvs",
    name: "Subpage",
    component: () => import("../views/As/SubPageView.vue"),
  },
  {
    path: "/testcvs",
    name: "TestCVS",
    component: () => import("../views/As/TestOfferView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/404View.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
